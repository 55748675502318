import pcIcon from '../assets/PC.svg'
import settingIcon from '../assets/thumbnail_Planning-orange.png'
import tailorIcon from '../assets/thumbnail_Seamstress-orange.png'
import customerIcon  from '../assets/thumbnail_Loyalty orange.png'

export const brandsDataEn = [
    {
        icon: pcIcon,
        textZone: 400,
        title: 'EASY INTEGRATION',
        text: 'Customisable end-to-end service for all your distribution channels. From IT integration to logistics, and from customer care to bespoke craftsmanship.'
    },
    {
        icon: tailorIcon,
        textZone: 450,
        title: 'Access to germany’s top craftsmen',
        text: 'With over 100 partners in Germany, we offer a wide spectrum of repair, alteration, restoration, and upcycling services, supporting the local economy and a variety of crafts.'
    },
    {
        icon: customerIcon,
        textZone: 400,
        title: 'IMPROVE YOUR CUSTOMER JOURNEY',
        text: ' Invest in long-term relationships and your brand equity. Expand touch-points with customers, reduce returns and warranty costs, while improving sales conversions.'
    },
    {
        icon: settingIcon,
        textZone: 400,
        title: 'DEVELOP A CIRCULAR BUSINESS MODEL',
        text: 'Increase your team’s efficiency. Track post-purchase and repair-related data to enhance future product design and customer engagement.'
    },
]

export const brandsDataDe = [
    {
        icon: pcIcon,
        textZone: 400,
        title: 'EINFACHE INTEGRATION',
        text: 'End-to-End-Service für alle Ihre Vertriebskanäle. Von der IT-Integration bis zur Logistik, von der Kundenbetreuung bis zur handwerklichen Maßanfertigung.'
    },
    {
        icon: tailorIcon,
        textZone: 400,
        title: 'BESTE DEUTSCHE HANDWERKER',
        text: 'Mit über 100 Partnern in Deutschland bieten wir ein breites Spektrum an Reparatur-, Änderungs- und Upcycling-Diensten an, und unterstützen die lokale Wirtschaft.'
    },
    {
        icon: customerIcon,
        textZone: 450,
        title: 'VERBESSERN SIE IHRE KUNDENERFAHRUNG',
        text: 'Investieren Sie in Ihren Markenwert. Erweitern Sie Kontaktpunkte mit Ihren Kunden, reduzieren Sie Retouren- und  Garantiekosten während Sie Ihre Verkaufszahlen verbessern.'
    },
    {
        icon: settingIcon,
        textZone: 400,
        title: 'EIN KREISLAUFGESCHÄFTSMODELL ENTWICKELN',
        text: 'Steigern Sie Teameffizienz. Sammeln Sie Daten, die nach dem Verkauf und bei Reparaturen anfallen, zur Verbesserung des Produktdesigns und Ihrer Kundenbindung.'
    },
]