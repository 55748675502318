import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import formBackground from '../../assets/form-background.jpg';
import orangeBackground from '../../assets/orange-background.svg';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import LoadingButton from 'components/LoadingButton';
import { getPage } from 'utils/pages';
import { Link } from '@reach/router';
import { ReactComponent as CheckedIcon } from '../../../images/svg/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from './white-unchecked.svg';
import ApiContext from 'api/context';

interface SubscribeFormProps {
  partnerPage?: boolean;
}

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  const de = i18next.language === 'de';
  return {
    formWrapper: {
      position: 'relative',
      minHeight: '200px',
      width: '100%',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    backgroundOverlay: {
      backgroundImage: `url(${formBackground})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      filter: 'brightness(40%)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    contentBlock: {
      position: 'relative',
      zIndex: 2,
      padding: de ? '35px' : '25px 40px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '25px',
      },
    },
    formTitle: {
      color: '#ffffff',
      textTransform: 'uppercase',
      marginBottom: '30px',
      fontWeight: 700,
      textAlign: 'left',
      fontSize: '25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    formText: {
      color: '#ffffff',
      fontSize: '16px',
      textAlign: 'left',
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
      },
    },
    formSuccessText: {
      color: '#EB672C',
      fontSize: '18px',
    },
    buttonTextBlock: {
      display: 'flex',
      gap: '40px',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '20px',
      },
    },
    subscribeButton: {
      width: '100%',
      textTransform: 'uppercase',
      maxWidth: '272px',
      textAlign: 'center',
      border: 'none',
      fontSize: s(2),
      fontWeight: 700,
      color: '#ffffff',
      background: 'transparent',
      backgroundImage: `url(${orangeBackground})`,
      backgroundSize: 'cover',
      padding: `${s(1.5)}px ${s(0)}px`,
      marginTop: '20px',
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        transform: 'scale(1.1)',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    input: {
      padding: '13px 14px!important',
      '@media (max-width: 1168px)': {
        padding: '14.8px 14px!important',
      },
    },
    checkBoxText: {
      fontSize: '0.8rem!important',
      color: '#ffffff',
    },
    formControlabel: {
      margin: '0',
      display: 'block',
    },
    checkBoxBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    formStyled: {
      flex: '0 0 40%',
    },
    textInfoBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };
});

interface SubscribeData {
  email: string;
  personalData: boolean;
}

const SubscribeForm: FC<SubscribeFormProps> = ({ partnerPage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const api = useContext(ApiContext);
  const [errorMsg, setErrorMsg] = useState('');
  const [sendDisabled, setSendDisabled] = useState(true);
  const [data, setData] = useState<SubscribeData>({
    email: '',
    personalData: false,
  });
  const [stateSuccess, setStateSuccess] = useState(false);

  const defaultValues = {
    email: '',
    personalData: false,
  };

  const { register, watch, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchAllFields = watch();

  const submitForm = async (formData: SubscribeData) => {
    setData(formData);
    await inviteUser(formData);
  };

  const inviteUser = async (formData: SubscribeData) => {
    setLoading(true);
    try {
      const { success = false, message = 'Unexpected error occurred' } = await api.club.invite(formData.email, partnerPage ? 'b2b' : 'craftsman');
      if (!success) {
        setErrorMsg(message);
      } else {
        setErrorMsg('');
        setStateSuccess(success);
      }
    } catch (err) {
      setErrorMsg(err.message || 'An error occurred while inviting the user');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSendDisabled(!watchAllFields.email || !watchAllFields.personalData);
  }, [watchAllFields]);

  return (
    <div className={classes.formWrapper}>
      <div className={classes.backgroundOverlay} />
      <div className={classes.contentBlock}>
        <div className={classes.buttonTextBlock}>
          <div className={classes.textInfoBlock}>
            <Typography component={'h2'} className={classes.formTitle}>
              {t('Did you know the repair market is expecting a 4,3% growth by 2030?')}
            </Typography>
            <Typography className={classes.formText}>
              {t('Stay up-to-date with the latest news in the repair industry subscribe now to our newsletter, with no strings attached!')}
            </Typography>
          </div>
          {stateSuccess ? (
            <Typography className={classes.formSuccessText}>{partnerPage ? t('You have successfully subscribed b2b!') : t('You have successfully subscribed!')}</Typography>
          ) : (
            <form className={classes.formStyled} onSubmit={handleSubmit(submitForm)}>
              <div>
                <TextField
                  inputRef={register}
                  name="email"
                  variant="outlined"
                  placeholder="Email"
                  InputProps={{
                    style: {
                      color: 'white',
                      height: '50px',
                    },
                    classes: {
                      input: classes.input,
                    },
                  }}
                  fullWidth
                  autoComplete="email"
                />
                <Box hidden={!errorMsg} marginTop={'1rem'}>
                  <Typography variant="h6" color="error">
                    {t('ErrorCustomClubMessage')}
                  </Typography>
                </Box>
              </div>
              <Box mb={'20px'} />
              <Box mb="1rem" className={classes.checkBoxBlock}>
                <FormControlLabel
                  className={classes.formControlabel}
                  control={<Checkbox checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />}
                  inputRef={register}
                  label
                  name="personalData"
                />
                <Box pl=".5rem" textAlign={'start'}>
                  <Typography className={classes.checkBoxText} variant="body2">
                    <Trans i18nKey="Check Box Loyalty Personal Data" t={t} components={[<Link className="base-link" to={getPage('privacyPolicy')} />]} />
                  </Typography>
                </Box>
              </Box>
              <LoadingButton
                className={`btn btn-secondary ${classes.subscribeButton}`}
                color="secondary"
                type="submit"
                variant="contained"
                loading={loading}
                disabled={sendDisabled}
              >
                {t('Subscribe')}
              </LoadingButton>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscribeForm;
