import React, { Suspense, useEffect, useState } from 'react';
import { Location, Router, LocationProvider } from '@reach/router';
import { Box, ThemeProvider, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import cookies from 'js-cookie';
import muiTheme from './mui-theme';
import Navbar from './components/Navbar';
import Homepage from './Homepage';
import NotFound from './components/NotFound';
import Product from './Product';
import Voucher from './Voucher';
import ScrollRestore from './components/ScrollRestore';
import Footer from './components/Footer';
import Imprint from './Imprint';
import AuthLayout from './Auth/components';
import GoogleRouterAnalytics from './GoogleAnalytics';
import FacebookRouterAnalytics from './FacebookAnalytics';
import { CITY_COOKIE_KEY, DEFAULT_CITY, IS_CITY_COOKIE_SELECTED } from './Common/constants/city';
import classes from './App.module.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import ReduxToastr from 'react-redux-toastr';
import { UserSlice } from './Auth/store';
import { handleError } from './utils/handleError';
import { useDispatch } from 'react-redux';
import { useCartApi } from './Cart/utils';
import { getRoute } from './utils/pages';
import i18next from 'i18next';
import i18n from './i18n';
import { setDelivery, setDoorToDoorCity } from './Cart/store/actions';
import { SupportedCities } from './enums';
import LanguageToggle from 'components/CheckUserLanguage/CheckUserLanguage';
import PartnershipsPage from './Partnerships';
import { UtilsProvider } from 'UtilsContext/UtilsContext';
import CraftsmenSubpage from 'CraftsmenSubpage/CraftsmenSubpage';

const TailorList = React.lazy(() => import('./Tailor/components/List'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const Club = React.lazy(() => import('./Club'));
const TailorClub = React.lazy(() => import('./TailorClub'));
const B2bClub = React.lazy(() => import('./B2bClub'));
const Terms = React.lazy(() => import('./Terms'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const Jobs = React.lazy(() => import('./Jobs'));
const Rating = React.lazy(() => import('./Rating'));
const Profile = React.lazy(() => import('./Profile'));
const CO2 = React.lazy(() => import('./CO2'));
const BlogList = React.lazy(() => import('./Blog/components/List'));
const BlogPost = React.lazy(() => import('./Blog/components/Post'));
const CartMain = React.lazy(() => import('./Cart'));
const Checkout = React.lazy(() => import('./Checkout/components'));
const OrderStatus = React.lazy(() => import('./OrderStatus/components'));
// const Contact = React.lazy(() => import('./Contact/components'));
const OrderEditPickupMain = React.lazy(() => import('./OrderEditPickup/components'));
const OrderEditNotAllowed = React.lazy(() => import('./OrderEditNotAllowed/components'));
const OrderEditDelivery = React.lazy(() => import('./OrderEditDelivery/components'));
const EditSuccess = React.lazy(() => import('./EditSuccess/components'));
const EstimationSuccess = React.lazy(() => import('./Estimation/Success/components'));
const EstimationRequest = React.lazy(() => import('./Estimation/Request'));
const HowItWorks = React.lazy(() => import('./HowItWorks'));
const TailorDetails = React.lazy(() => import('./Tailor/components/Details'));
const PriceGuide = React.lazy(() => import('./PriceGuide/PriceGuide'));

export default function App() {
  const dispatch = useDispatch();
  const cartApi = useCartApi();
  const { t } = useTranslation();
  const [languageChanged, setLanguageChanged] = useState(false);

  const hjid = process.env.REACT_APP_HOTJAR_ID;
  const hjsv = process.env.REACT_APP_HOTJAR_VERSION;
  const C0004 = localStorage.getItem('C0004');
  const { reloadUser } = UserSlice.actions;
  const city = sessionStorage.getItem('shippingCity');
  const cityParsed = city ? (city as SupportedCities) : null;

  useEffect(() => {
    setLanguageChanged(true);
    const timeout = setTimeout(() => setLanguageChanged(false), 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [i18next.language]);

  useEffect(() => {
    const userCookie = cookies.get('user');

    if (userCookie) {
      try {
        const parsed = JSON.parse(window.atob(userCookie));

        if (!parsed.active) {
          handleError('USER_RELOAD', 'User is blocked');
          return cookies.remove('user');
        }

        dispatch(reloadUser(parsed));
        i18n.changeLanguage(parsed.preferredLanguage);
      } catch (err) {
        handleError('USER_RELOAD', "Can't reload user, broken cookie");
        cookies.remove('user');
      }
    }

    const numBoxes = localStorage.getItem('numBoxes');

    if (!numBoxes) {
      localStorage.setItem('numBoxes', '0');
    }

    if (cookies.get(IS_CITY_COOKIE_SELECTED) !== 'true' || !cookies.get(CITY_COOKIE_KEY)) {
      cookies.set(CITY_COOKIE_KEY, DEFAULT_CITY, { expires: 30 });
    }
    window.scrollTo(0, 0);

    if (C0004) {
      if (hjid && hjsv) {
        hotjar.initialize(+hjid, +hjsv);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reloadCart = async () => {
      try {
        await cartApi.reloadCartFromCookie();
      } catch (err) {
        handleError('CART_RELOAD', 'Error reloading cart');
      }
    };
    reloadCart();
  }, []);

  useEffect(() => {
    const deliveryType = sessionStorage.getItem('deliveryType');
    dispatch(
      setDelivery({
        doorToDoorOrder: deliveryType ? deliveryType === 'door-to-door' : true,
        shippingOrder: deliveryType === 'shipping',
      })
    );
    sessionStorage.setItem('deliveryType', deliveryType ? deliveryType : 'door-to-door');

    if (cityParsed) {
      dispatch(setDoorToDoorCity(cityParsed));
      sessionStorage.setItem('shippingCity', cityParsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityParsed]);

  return (
    <ThemeProvider theme={muiTheme}>
      <UtilsProvider>
        <ReduxToastr timeOut={4000} position="top-right" preventDuplicates />
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Location>{() => <Navbar />}</Location>
          <Box component="main" flex={1} pb={3}>
            {C0004 === 'true' && (
              <>
                <LocationProvider>
                  <LanguageToggle />
                  <GoogleRouterAnalytics />
                </LocationProvider>
                <LocationProvider>
                  <FacebookRouterAnalytics />
                </LocationProvider>
              </>
            )}

            <Suspense
              fallback={
                <div className={classes.container}>
                  <div className="text-center">
                    <div className="d-flex justify-content-center py-5">
                      <CircularProgress className={classes.circularProgress} />
                      <div className="sr-only">{t('Loading...')}</div>
                    </div>
                  </div>
                </div>
              }
            >
              {languageChanged && (
                <div className={classes.container}>
                  <div className="d-flex justify-content-center py-5">
                    <CircularProgress className={classes.circularProgress} color="secondary" />
                    <div className="text-center sr-only">{t('Loading...')}</div>
                  </div>
                </div>
              )}
              {!languageChanged && (
                <Router>
                  {/* @ts-ignore */}
                  <ScrollRestore path="/">
                    {/* @ts-ignore */}
                    <Homepage path="/" />
                    {/* @ts-ignore */}
                    <Product path={`${getRoute('product')}/*`} />
                    {/* @ts-ignore */}
                    <CartMain path={getRoute('cart')} />
                    {/* @ts-ignore */}
                    <Checkout path={`${getRoute('checkout')}/*`} />
                    {/* @ts-ignore */}
                    <OrderStatus path={getRoute('orderStatus')} />
                    {/* @ts-ignore */}
                    <OrderEditPickupMain path={getRoute('orderEditPickUp')} />
                    {/* @ts-ignore */}
                    <OrderEditDelivery path={getRoute('orderEditDelivery')} />
                    {/* @ts-ignore */}
                    <EditSuccess path={getRoute('editSuccess')} />
                    {/* @ts-ignore */}
                    <OrderEditNotAllowed path={getRoute('orderEditNotAllowed')} />
                    {/* @ts-ignore */}
                    <Rating path={getRoute('rating')} />
                    <EstimationRequest path={getRoute('estimation')} />
                    <EstimationSuccess path={getRoute('estimationSuccess')} />
                    {/* @ts-ignore */}
                    <HowItWorks path={getRoute('faq')} />
                    {/* @ts-ignore */}
                    <TailorDetails path={getRoute('tailorProfile')} />
                    {/* @ts-ignore */}
                    <TailorList path={getRoute('tailorList')} />
                    {/* @ts-ignore */}
                    <BlogPost path={getRoute('blogPost')} />
                    {/* @ts-ignore */}
                    <BlogList path={getRoute('blogList')} />
                    {/* @ts-ignore */}
                    <Club path={getRoute('club')} />
                    {/* @ts-ignore */}
                    <TailorClub path={getRoute('tailorClub')} />
                    {/* @ts-ignore */}
                    <B2bClub path={getRoute('b2bClub')} />
                    {/* @ts-ignore */}
                    <AboutUs path={getRoute('aboutUs')} />
                    {/* @ts-ignore */}
                    <CO2 path={getRoute('co2')} />
                    {/* @ts-ignore */}
                    <Imprint path={getRoute('imprint')} />
                    {/* @ts-ignore */}
                    <PrivacyPolicy path={getRoute('privacyPolicy')} />
                    {/* @ts-ignore */}
                    <Terms path={getRoute('terms')} />
                    {/* @ts-ignore */}
                    <PriceGuide path={getRoute('priceGuide')} />
                    {/* @ts-ignore */}
                    <Voucher path={`${getRoute('voucher')}/*`} />
                    {/* @ts-ignore */}
                    <PartnershipsPage path={`${getRoute('brandPartnerships')}/*`} />
                    {/* @ts-ignore */}
                    <CraftsmenSubpage path={`${getRoute('forCraftsmen')}/*`} />
                    {/* @ts-ignore */}
                    <AuthLayout path={`${getRoute('auth')}/*`} />
                    <Profile path={`${getRoute('profile')}/*`} />
                    {/* @ts-ignore */}
                    <Jobs path={getRoute('jobs')} />
                    {/* @ts-ignore */}
                    {/*<Contact path="contact" />*/}
                    <NotFound default />
                  </ScrollRestore>
                </Router>
              )}
            </Suspense>

            {/*<CommonDevCityCookie />*/}
          </Box>

          <Footer />
        </Box>
      </UtilsProvider>
    </ThemeProvider>
  );
}
