import logoGts from '../assets/logo GTS.png';
import logoHessnatur from '../assets/logo hessnature.png';
import logoMono from '../assets/logo monoquick.png';
import logoWeDress from '../assets/logo we dress.png';
import logoRevolution from '../assets/logo fashion revolution.png';
import logoCosh from '../assets/logo cosh.png';
import logoBartatex from '../assets/logo bartatex.png';
import logoWortmarke from '../assets/logo wortmarke.png'
import logoTrustedFlip from '../assets/trusted-flip.png';

export const brandsLogos = [
    {
        img: logoWortmarke,
        width: '200px'
    },
    {
        img: logoGts,
        width: '200px'
    },
    {
        img: logoHessnatur,
        width: '200px'
    },
    {
        img: logoMono,
        width: '200px'
    },
    {
        img: logoWeDress,
        width: '250px',
        marginRight: '25px',
    },
    {
        img: logoRevolution,
        width: '80px',
        marginRight: '25px',
    },
    {
        img: logoTrustedFlip,
        width: '200px',
        marginRight: '25px',
    },
    {
        img: logoBartatex,
        width: '100px',
        marginRight: '25px',
    },
    {
        img: logoCosh,
        width: '100px'
    },
]