import i18n from '../i18n';
import { blogItemsDe, blogItemsEn } from '../Blog/data/items';

const PAGES = {
  en: {
    aboutUs: 'about-us',
    auth: 'auth',
    blogList: 'blog',
    blogPost: 'blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'create-password',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'forgot-password',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'imprint',
    jobs: 'jobs',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-delivery/:customerId/:orderId',
    orderEditNotAllowed: 'edit-not-allowed',
    orderEditPickUp: 'edit-pickup/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'privacy-policy',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registration',
    resetPassword: 'reset-password',
    select: 'select',
    tailorList: 'our-partners',
    tailorProfile: 'our-partners/:tailorName',
    terms: 'terms-and-conditions',
    verifyEmail: 'verify-email',
    voucher: 'voucher',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'brand-partnerships',
    forCraftsmen: 'for-craftsmen',
    tailorClub: 'tailor-club',
    b2bClub: 'b2b-club',
  },
  de: {
    aboutUs: 'ueber-uns',
    auth: 'auth',
    blogList: 'unser-blog',
    blogPost: 'unser-blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'passwort-erstellen',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'passwort-vergessen',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'impressum',
    jobs: 'karriere',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-lieferung/:customerId/:orderId',
    orderEditNotAllowed: 'bearbeiten-nicht-erlaubt',
    orderEditPickUp: 'bearbeiten-abholung/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'datenschutzerklaerung',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registriere-dich',
    resetPassword: 'passwort-zurücksetzen',
    select: 'select',
    tailorList: 'unsere-partner',
    tailorProfile: 'unsere-partner/:tailorName',
    terms: 'allgemeine-geschaeftsbedingungen',
    verifyEmail: 'verify-email',
    voucher: 'gutschein',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'marken-partnerschaften',
    forCraftsmen: 'fur-handwerk',
    tailorClub: 'tailor-club',
    b2bClub: 'b2b-club',
  },
};

const simpleRoutes = {
  en: {
    'product/pants': 'product/hosen',
    'product/sneakers': 'product/sneaker',
    'product/bags': 'product/taschen',
    'product/top-parts': 'product/oberteile',
    'product/boots': 'product/boots',
    'product/pumps': 'product/pumps',
    'product/jackets': 'product/jacken',
    'product/dresses': 'product/kleider',
  },
  de: {
    'product/hosen': 'product/pants',
    'product/sneaker': 'product/sneakers',
    'product/taschen': 'product/bags',
    'product/oberteile': 'product/top-parts',
    'product/boots': 'product/boots',
    'product/pumps': 'product/pumps',
    'product/jacken': 'product/jackets',
    'product/kleider': 'product/dresses',
  },
};

const complexRoutes = {
  en: {
    'product/pants/jeans': 'product/hosen/jeans',
    'product/pants/fabric-pants': 'product/hosen/stoffhose',
    'product/sneakers/leather': 'product/sneaker/leder',
    'product/sneakers/fabric-and-artificial-leather': 'product/sneaker/stoff-und-kunstleder',
    'product/bags/leather-and-artificial-leather': 'product/taschen/leder-und-kunstleder',
    'product/bags/fabric': 'product/taschen/stoff',
    'product/top-parts/shirts': 'product/oberteile/hemd',
    'product/top-parts/sweaters': 'product/oberteile/pullover',
    'product/boots/boots-and-regular-shoes': 'product/boots/boots-und-normale-schuhe',
    'product/boots/welted-shoes': 'product/boots/rahmengenahte-schuhe',
    'product/pumps/pumps': 'product/pumps/pumps',
    'product/pumps/ankle-boots': 'product/pumps/stiefeletten',
    'product/pumps/ballet-flats': 'product/pumps/ballerinas',
    'product/jackets/jackets': 'product/jacken/jacke',
    'product/jackets/leather-jackets': 'product/jacken/lederjacke',
    'product/jackets/coats': 'product/jacken/mantel',
    'product/jackets/blazers-or-jackets': 'product/jacken/blazer-oder-sakko',
    'product/dresses/dresses': 'product/kleider/kleid',
    'product/dresses/skirts': 'product/kleider/rock'
  },
  de: {
    'product/hosen/jeans': 'product/pants/jeans',
    'product/hosen/stoffhose': 'product/pants/fabric-pants',
    'product/sneaker/leder': 'product/sneakers/leather',
    'product/sneaker/stoff-und-kunstleder': 'product/sneakers/fabric-and-artificial-leather',
    'product/taschen/leder-und-kunstleder': 'product/bags/leather-and-artificial-leather',
    'product/taschen/stoff': 'product/bags/fabric',
    'product/oberteile/hemd': 'product/top-parts/shirts',
    'product/oberteile/pullover': 'product/top-parts/sweaters',
    'product/boots/boots-und-normale-schuhe': 'product/boots/boots-and-regular-shoes',
    'product/boots/rahmengenahte-schuhe': 'product/boots/welted-shoes',
    'product/pumps/pumps': 'product/pumps/pumps',
    'product/pumps/stiefeletten': 'product/pumps/ankle-boots',
    'product/pumps/ballerinas': 'product/pumps/ballet-flats',
    'product/jacken/jacke': 'product/jackets/jackets',
    'product/jacken/lederjacke': 'product/jackets/leather-jackets',
    'product/jacken/mantel': 'product/jackets/coats',
    'product/jacken/blazer-oder-sakko': 'product/jackets/blazers-or-jackets',
    'product/kleider/kleid': 'product/dresses/dresses',
    'product/kleider/rock': 'product/dresses/skirts'
  },
};

const complexTrippleRoutes = {
  en: {
    'product/pants/jeans/plug-holes-or-worn-areas' : 'product/hosen/jeans/loecher-oder-abgenutzte-bereiche-stopfen',
    'product/pants/jeans/step-stuffing': 'product/hosen/jeans/schritt-stopfen',
    'product/pants/jeans/replace-zipper': 'product/hosen/jeans/reissverschluss-ersetzen',
    'product/pants/jeans/shorten-pants': 'product/hosen/jeans/hosen-kuerzen',
    'product/pants/jeans/close-the-torn-seam': 'product/hosen/jeans/gerissene-naht-schliessen',
    'product/pants/jeans/sew-the-waist-more-tightly': 'product/hosen/jeans/taille-enger-naehen',
    'product/pants/jeans/sew-back-the-belt-loop': 'product/hosen/jeans/guertelschleife-annaehen',

    //
    'product/pants/fabric-pants/replace-zipper': 'product/hosen/stoffhose/reissverschluss-ersetzen',
    'product/pants/fabric-pants/shorten-pants': 'product/hosen/stoffhose/hosen-kuerzen',
    'product/pants/fabric-pants/close-the-torn-seam': 'product/hosen/stoffhose/gerissene-naht-schliessen',
    'product/pants/fabric-pants/sew-the-waist-more-tightly': 'product/hosen/stoffhose/taille-enger-naehen',
    'product/pants/fabric-pants/sewing-buttons': 'product/hosen/stoffhose/knoepfe-annaehen',
    'product/pants/fabric-pants/sew-back-the-belt-loop': 'product/hosen/stoffhose/guertelschleife-annaehen',

    //
    'product/sneakers/leather/classic-cleaning': 'product/sneaker/leder/classic-reinigung',
    'product/sneakers/leather/premium-cleaning': 'product/sneaker/leder/premium-reinigung',
    'product/sneakers/leather/gluing-the-sole': 'product/sneaker/leder/kleben-der-sohle',
    'product/sneakers/leather/renew-rubber-heels': 'product/sneaker/leder/gummiabsaetze-erneuern',
    'product/sneakers/leather/renew-rubber-soles': 'product/sneaker/leder/gummisohlen-erneuern',
    'product/sneakers/leather/repair-heels': 'product/sneaker/leder/fersen-reparieren',
    'product/sneakers/leather/stretch': 'product/sneaker/leder/stretch',

    //
    'product/sneakers/fabric-and-artificial-leather/classic-cleaning': 'product/sneaker/stoff-und-kunstleder/classic-reinigung',
    'product/sneakers/fabric-and-artificial-leather/gluing-the-sole': 'product/sneaker/stoff-und-kunstleder/kleben-der-sohle',
    'product/sneakers/fabric-and-artificial-leather/renew-rubber-heels': 'product/sneaker/stoff-und-kunstleder/gummiabsaetze-erneuern',
    'product/sneakers/fabric-and-artificial-leather/renew-rubber-soles': 'product/sneaker/stoff-und-kunstleder/gummisohlen-erneuern',
    'product/sneakers/fabric-and-artificial-leather/repair-heels': 'product/sneaker/stoff-und-kunstleder/fersen-reparieren',
    'product/sneakers/fabric-and-artificial-leather/plug-a-hole': 'product/sneaker/stoff-und-kunstleder/loch-stopfen',

    //
    'product/bags/leather-and-artificial-leather/sew-handle-or-strap': 'product/taschen/leder-und-kunstleder/griff-oder-gurt-naehen',
    'product/bags/leather-and-artificial-leather/close-the-torn-seam': 'product/taschen/leder-und-kunstleder/gerissene-naht-schliessen',
    'product/bags/leather-and-artificial-leather/replace-the-zipper': 'product/taschen/leder-und-kunstleder/reissverschluss-austauschen',
    'product/bags/leather-and-artificial-leather/polishing-of-leather-bags': 'product/taschen/leder-und-kunstleder/polieren-von-ledertaschen',
    'product/bags/fabric/sew-handle-or-strap': 'product/taschen/stoff/griff-oder-gurt-naehen',
    'product/bags/fabric/close-the-torn-seam': 'product/taschen/stoff/gerissene-naht-schliessen',
    'product/bags/fabric/replace-the-zipper': 'product/taschen/stoff/reissverschluss-austauschen',

    //
    'product/top-parts/shirts/shortening-sleeves': 'product/oberteile/hemd/aermel-kuerzen',
    'product/top-parts/shirts/shorten-the-length': 'product/oberteile/hemd/laenge-kuerzen',
    'product/top-parts/shirts/close-the-torn-seam': 'product/oberteile/hemd/gerissene-naht-schliessen',
    'product/top-parts/shirts/sew-more-tightly': 'product/oberteile/hemd/enger-naehen',
    'product/top-parts/shirts/sewing-buttons': 'product/oberteile/hemd/knoepfe-annaehen',
    'product/top-parts/sweaters/plug-holes-or-worn-areas': 'product/oberteile/pullover/loecher-oder-abgenutzte-bereiche-stopfen',
    'product/top-parts/sweaters/patching-elbows': 'product/oberteile/pullover/ellbogen-patchen',
    'product/top-parts/sweaters/pilling': 'product/oberteile/pullover/pilling'
  },
  de: {
    'product/hosen/jeans/loecher-oder-abgenutzte-bereiche-stopfen': 'product/pants/jeans/plug-holes-or-worn-areas',
    'product/hosen/jeans/schritt-stopfen': 'product/pants/jeans/step-stuffing',
    'product/hosen/jeans/reissverschluss-ersetzen': 'product/pants/jeans/replace-zipper',
    'product/hosen/jeans/hosen-kuerzen': 'product/pants/jeans/shorten-pants',
    'product/hosen/jeans/gerissene-naht-schliessen': 'product/pants/jeans/close-the-torn-seam',
    'product/hosen/jeans/taille-enger-naehen': 'product/pants/jeans/sew-the-waist-more-tightly',
    'product/hosen/jeans/guertelschleife-annaehen': 'product/pants/jeans/sew-back-the-belt-loop',

    //
    'product/hosen/stoffhose/reissverschluss-ersetzen': 'product/pants/fabric-pants/replace-zipper',
    'product/hosen/stoffhose/hosen-kuerzen': 'product/pants/fabric-pants/shorten-pants',
    'product/hosen/stoffhose/gerissene-naht-schliessen': 'product/pants/fabric-pants/close-the-torn-seam',
    'product/hosen/stoffhose/taille-enger-naehen': 'product/pants/fabric-pants/sew-the-waist-more-tightly',
    'product/hosen/stoffhose/knoepfe-annaehen': 'product/pants/fabric-pants/sewing-buttons',
    'product/hosen/stoffhose/guertelschleife-annaehen': 'product/pants/fabric-pants/sew-back-the-belt-loop',

    //
    'product/sneaker/leder/classic-reinigung': 'product/sneakers/leather/classic-cleaning',
    'product/sneaker/leder/premium-reinigung': 'product/sneakers/leather/premium-cleaning',
    'product/sneaker/leder/kleben-der-sohle': 'product/sneakers/leather/gluing-the-sole',
    'product/sneaker/leder/gummiabsaetze-erneuern': 'product/sneakers/leather/renew-rubber-heels',
    'product/sneaker/leder/gummisohlen-erneuern': 'product/sneakers/leather/renew-rubber-soles',
    'product/sneaker/leder/fersen-reparieren': 'product/sneakers/leather/repair-heels',
    'product/sneaker/leder/stretch': 'product/sneakers/leather/stretch',

    //
    'product/sneaker/stoff-und-kunstleder/classic-reinigung': 'product/sneakers/fabric-and-artificial-leather/classic-cleaning',
    'product/sneaker/stoff-und-kunstleder/kleben-der-sohle': 'product/sneakers/fabric-and-artificial-leather/gluing-the-sole',
    'product/sneaker/stoff-und-kunstleder/gummiabsaetze-erneuern': 'product/sneakers/fabric-and-artificial-leather/renew-rubber-heels',
    'product/sneaker/stoff-und-kunstleder/gummisohlen-erneuern': 'product/sneakers/fabric-and-artificial-leather/renew-rubber-soles',
    'product/sneaker/stoff-und-kunstleder/fersen-reparieren': 'product/sneakers/fabric-and-artificial-leather/repair-heels',
    'product/sneaker/stoff-und-kunstleder/loch-stopfen': 'product/sneakers/fabric-and-artificial-leather/plug-a-hole',

    //
    'product/taschen/leder-und-kunstleder/griff-oder-gurt-naehen': 'product/bags/leather-and-artificial-leather/sew-handle-or-strap',
    'product/taschen/leder-und-kunstleder/gerissene-naht-schliessen': 'product/bags/leather-and-artificial-leather/close-the-torn-seam',
    'product/taschen/leder-und-kunstleder/reissverschluss-austauschen': 'product/bags/leather-and-artificial-leather/replace-the-zipper',
    'product/taschen/leder-und-kunstleder/polieren-von-ledertaschen': 'product/bags/leather-and-artificial-leather/polishing-of-leather-bags',
    'product/taschen/stoff/griff-oder-gurt-naehen': 'product/bags/fabric/sew-handle-or-strap',
    'product/taschen/stoff/gerissene-naht-schliessen': 'product/bags/fabric/close-the-torn-seam',
    'product/taschen/stoff/reissverschluss-austauschen': 'product/bags/fabric/replace-the-zipper',

    //
    'product/oberteile/hemd/aermel-kuerzen': 'product/top-parts/shirts/shortening-sleeves',
    'product/oberteile/hemd/laenge-kuerzen': 'product/top-parts/shirts/shorten-the-length',
    'product/oberteile/hemd/gerissene-naht-schliessen': 'product/top-parts/shirts/close-the-torn-seam',
    'product/oberteile/hemd/enger-naehen': 'product/top-parts/shirts/sew-more-tightly',
    'product/oberteile/hemd/knoepfe-annaehen': 'product/top-parts/shirts/sewing-buttons',
    'product/oberteile/pullover/loecher-oder-abgenutzte-bereiche-stopfen': 'product/top-parts/sweaters/plug-holes-or-worn-areas',
    'product/oberteile/pullover/ellbogen-patchen': 'product/top-parts/sweaters/patching-elbows',
    'product/oberteile/pullover/pilling': 'product/top-parts/sweaters/pilling'
  },
};


const getRoute = (pageName: string) => {
  return PAGES[i18n.language][pageName];
};

const getPage = (pageName: string) => {
  return `/${PAGES[i18n.language][pageName]}`;
};

const getLanguageChangePageRedirect = (prevLanguage: 'de' | 'en', language: 'de' | 'en', pathname: string) => {
  // if (pathname.includes('product')) {
  //   return pathname;
  // }
  const prevSimpleRoutes = simpleRoutes[prevLanguage];
  const prevComplexRoutes = complexRoutes[prevLanguage];
  const prevComplexTrippleRoutes = complexTrippleRoutes[prevLanguage];

  for (const [prevRoute, newRoute] of Object.entries(prevComplexTrippleRoutes)) {
    if (pathname.includes(prevRoute)) {
      const newPath = pathname.replace(prevRoute, newRoute);
      return newPath;
    }
  }

  for (const [prevRoute, newRoute] of Object.entries(prevComplexRoutes)) {
    if (pathname.includes(prevRoute)) {
      const newPath = pathname.replace(prevRoute, newRoute);
      return newPath;
    }
  }

  for (const [prevRoute, newRoute] of Object.entries(prevSimpleRoutes)) {
    if (pathname.includes(prevRoute)) {
      const newPath = pathname.replace(prevRoute, newRoute);
      return newPath;
    }
  }

  const currentPageKey = Object.entries(PAGES[prevLanguage]).reduce((prev, [key, value]) => {
    if (key !== 'homepage' && pathname.includes(value)) {
      return key;
    }
    return prev;
  }, '');

  if (currentPageKey) {
    const newPathRoot = PAGES[language][currentPageKey];

    if (currentPageKey === 'blogList') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [_, blogItemName] = pathname.split(PAGES[prevLanguage][currentPageKey]);
      blogItemName = blogItemName.substring(1);
      const englishBlogs = blogItemsEn.map(({ id }) => id);
      const germanBlogs = blogItemsDe.map(({ id }) => id);

      if (language === 'de') {
        return `/${newPathRoot}/${germanBlogs[englishBlogs.indexOf(blogItemName)]}`;
      } else if (language === 'en') {
        return `/${newPathRoot}/${englishBlogs[germanBlogs.indexOf(blogItemName)]}`;
      }
    }

    return pathname.replace(PAGES[prevLanguage][currentPageKey], PAGES[language][currentPageKey]);
  }

  return '/';
};

const normalizeString = (str: string): string => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const localizePath = (path: string, currentLanguage: string): string => {
  const pathMapping: Record<string, Record<string, string>> = {
    'en': {
      'create-password': 'create-password',
      'edit-not-allowed': 'edit-not-allowed',
      'edit-delivery': 'edit-delivery',
      'edit-pickup': 'edit-pickup'
    },
    'de': {
      'create-password': 'passwort-erstellen',
      'edit-not-allowed': 'bearbeiten-nicht-erlaubt',
      'edit-delivery': 'edit-lieferung',
      'edit-pickup': 'bearbeiten-abholung',
    },
  };

  const languageMappings = pathMapping[currentLanguage] || pathMapping['en'] || pathMapping['de'];

  const pathSegments = path.split('/').map(segment => normalizeString(segment.toLowerCase()));

  const replaceDynamicParams = (localizedSegments: string[], originalSegments: string[]): string[] => {
    return localizedSegments.map((segment, index) => {
      const hasDynamicParameters = originalSegments[index].includes(':');

      if (hasDynamicParameters) {
        const replacedDynamic = languageMappings[originalSegments[index]] || originalSegments[index];
        return replacedDynamic;
      }

      return segment;
    });
  };

  const localizedSegments = pathSegments.map(segment => {
    return languageMappings[segment] || segment;
  });

  const replacedDynamicSegments = replaceDynamicParams(localizedSegments, pathSegments);

  const localizedPath = replacedDynamicSegments.join('/');
  return localizedPath;
};

export { PAGES, getPage, getRoute, getLanguageChangePageRedirect, localizePath };