import { Box, Button, Container, FormControlLabel, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import AppCartDisabledFrame from '../../../components/CartDisabledFrame';
import LoadingButton from '../../../components/LoadingButton';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import { AmountType } from '../../../Cart/interfaces/item';
import { sendEvent } from '../../../utils/google-analytics';
import { removePayPalGuard } from '../../../utils/remove-paypal-guard';
import { Product } from '../../../types';
import RunningLine from 'components/RunningLine';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setCreationTime, setVoucherCart } from 'Cart/store/actions';
import i18n from 'i18n';
import { uploadImagesFiles } from 'Estimation/store/actions';
import { useUtilsContext } from 'UtilsContext/UtilsContext';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

// env
const cartEnabled: boolean = process.env.REACT_APP_CART_ENABLED === 'true';
/*const cartEnabled = false;  // HARDCODE*/

// props; exporting to tests
export interface ProductDetailsStepFormProps {
  product: Product;
  onSubmit(amount?: number, comment?: string, creationTime?: number, images?: File[]): Promise<void>;
  backLinkHref?: string;
}

export interface RepairImage {
  file: File;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileContent: string;
  creationTime: number;
}

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },

    '& svg': {
      fill: '#000000',
      width: '40px',
      height: '40px',
    },

    '&:hover': {
      background: 'transparent',
    },
  },
}));

/**
 * Product "Further Details" presentational form component.
 */
export default function ProductDetailsStepForm({ product, onSubmit, backLinkHref = '..' }: ProductDetailsStepFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setImages } = useUtilsContext();
  const maxUploads = 10;
  const langCookie = cookies.get('language') || '';
  const headingHtmlId = 'ProductDetailsStepForm-heading';
  const commentFieldHtmlId = 'ProductDetailsStepForm-commentField';
  const categoryId = product.productCategory.seo?.[i18n.language];
  const typeId = product.productType.seo?.[i18n.language];
  const repairId = product.seo?.[i18n.language];
  const priceCent = product.priceCent;
  const [comment, setComment] = useState('');
  const [newCreationTime] = useState(Date.now());
  const [repairImages, setRepairImages] = useState<RepairImage[]>([]);

  let initialAmount = 1;
  if (product.input?.type === AmountType.LENGTH) {
    initialAmount = 0;
  }

  const [amount, setAmount] = useState(initialAmount);
  const [loading, setLoading] = useState(false);
  const { detailedDescription } = product;

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, repairCreationTime: number) => {
    const files = Array.from(e.target.files ?? []);

    const filePromises = files.map((file) => {
      return new Promise<RepairImage>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            file: file,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileContent: reader.result as string,
            creationTime: repairCreationTime,
          });
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    });

    const repairImages = await Promise.all(filePromises);

    setRepairImages((prevImages) => [...prevImages, ...repairImages]);
  };

  function handleAmountChange(e) {
    setAmount(e.target.value);
  }
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    const setVoucherInactive = false;

    dispatch(setVoucherCart(setVoucherInactive));
    dispatch(uploadImagesFiles());

    localStorage.setItem('voucherItem', JSON.stringify(setVoucherInactive));

    try {
      //@ts-ignore
      await onSubmit(Number(amount), comment, newCreationTime, repairImages);
      setImages((prevImages) => [
        ...prevImages,
        ...repairImages.map((ri) => ({
          ...ri,
        })),
      ]);
      dispatch(setCreationTime(newCreationTime));
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      setLoading(false);
      // TODO: handle error
    } finally {
      removePayPalGuard();
      sendEvent('repair', 'add_repair_to_cart');
    }
  }

  function renderAmountField() {
    if (product.input?.type === AmountType.NO_INPUT) {
      return null;
    }

    const amountFieldHtmlId = 'ProductDetailsStepForm-amountField';
    const step = product.input?.default ?? 1;
    return (
      <>
        <Typography component="label" gutterBottom htmlFor={amountFieldHtmlId}>
          {product.input?.text ? t(product.input?.text) : t('Amount')}
        </Typography>
        :
        <Box display="flex" alignItems="center" mb={3}>
          <Box width={{ xs: '50%', sm: '33%' }}>
            <TextField
              fullWidth
              id={amountFieldHtmlId}
              inputProps={{
                min: +step,
                step: +step,
                style: { textAlign: 'center' },
              }}
              name="quantity"
              onChange={handleAmountChange}
              required
              //required={quantityInput.required}
              size="small"
              type="number"
              value={amount}
              variant="outlined"
            />
          </Box>
          <Box mr={1} />
          <Typography variant="body1">{product.input?.unit}</Typography>
        </Box>
      </>
    );
  }

  function renderImageField(repairCreationTime: number) {
    const handleFileChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e, repairCreationTime);
    };

    return (
      <>
        <FormControlLabel
          style={{
            marginBottom: 0,
          }}
          control={<input id="file-upload" type="file" accept="image/*, video/mp4" onChange={handleFileChangeWrapper} style={{ display: 'none' }} multiple />}
          label={
            <Button disableRipple color="secondary" component="div" className={classes.uploadButton}>
              <AddAPhotoIcon /> + {t('Upload Image')} ( {repairImages.length} / {maxUploads} )*
            </Button>
          }
        />
        <Typography>{t('ImageProductUploadText')}</Typography>
        <Box mb={4} />
        <Box>
          <Typography color="secondary" variant="caption">
            {t('fields_with_asterisks')}
          </Typography>
        </Box>
        <Box mb={2} />
      </>
    );
  }

  function renderWhatsApp() {
    if (cartEnabled) {
      return (
        <>
          <Box mb={6} />
          <AppWhatsApp />
        </>
      );
    }
    return (
      <>
        <Box mb={4} />
        <AppCartDisabledFrame />
      </>
    );
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <ProductHeader categoryId={categoryId} typeId={typeId} repairId={repairId} priceCent={priceCent} backLinkHref={backLinkHref} />
        <Box mb={3} />
        <Typography className="with-bar" id={headingHtmlId} variant="h1">
          {t('Further Details')}
        </Typography>
        <Box mb={3} />
        <form aria-labelledby={headingHtmlId} onSubmit={handleSubmit}>
          <Paper>
            {renderAmountField()}
            <Typography component="label" gutterBottom htmlFor={commentFieldHtmlId}>
              {t('Comments')}
            </Typography>
            :
            <TextField
              fullWidth
              id={commentFieldHtmlId}
              multiline
              name="comment"
              onChange={handleCommentChange}
              rows={5}
              size="small"
              value={comment}
              variant="outlined"
            />
            {detailedDescription && (
              <>
                <Box mb={3} />
                <Typography variant="body1">{(!langCookie ? detailedDescription.de : detailedDescription[langCookie]).replaceAll('\\u003a', ':')}</Typography>
                <Box mb={4} />
              </>
            )}
            {renderImageField(newCreationTime)}
          </Paper>
          <Box mb={4} />
          <Box textAlign="center">
            <LoadingButton color="secondary" disabled={!cartEnabled || repairImages.length === 0} loading={loading} type="submit" variant="contained">
              {t('Add to Cart')}
            </LoadingButton>
          </Box>
        </form>
        {renderWhatsApp()}
      </Container>
    </Box>
  );
}
