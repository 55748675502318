import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { brandsDataEn, brandsDataDe } from 'Partnerships/data/brandsData';
import BrandsItem from '../BrandsItem/BrandsItem';
import { useTranslation } from 'react-i18next';
import expertiseImage from '../../assets/picture 2 - RR expertise.png';
import i18next from 'i18next';
import RunningPartnershipsLine from '../RunningLine';
import HowRepairsWorks from 'CraftsmenSubpage/components/HowItWorks/HowItWorks';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    brandsWrapper: {
      background: '#ffffff',
      paddingBottom: '30px',
    },
    brandsInner: {
      marginBottom: '45px',
    },
    featuresList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '5px',
      alignItems: 'center',
      listStyle: 'none',
      padding: '40px 0',
      marginBottom: '50px',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        marginBottom: '0',
        gap: '10px',
      },
    },
    ecosystemTitle: {
      fontSize: s(3.5),
      textAlign: 'center',
      maxWidth: '750px',
      margin: '0 auto 50px auto',
      paddingTop: '80px',
    },
    ecosystemText: {
      textAlign: 'justify',
      fontSize: '18px',
      marginBottom: '80px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    runningLine: {
      marginBottom: '80px',
    },
    expertiseSection: {
      paddingBottom: '20px',
    },
    expertiseText: {
      maxWidth: '425px',
      fontSize: '18px',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    expertiseWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftBlock: {
      flex: '0 0 50%',
    },
    rigthBlock: {
      flex: '0 0 50%',
      textAlign: 'right',
    },
    expertisTitle: {
      fontSize: s(3.5),
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    leftPicture: {
      height: '440px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
  };
});

const BrandsPartnerships = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const brandsData = i18next.language === 'en' ? brandsDataEn : brandsDataDe;

  return (
    <div className={classes.brandsWrapper}>
      <div className={`${classes.brandsInner} container`}>
        <div>
          <Typography component={'h2'} className={classes.ecosystemTitle}>
            {t('weOfferBrands')}
          </Typography>
        </div>
        <ul className={classes.featuresList}>
          {brandsData.map((feature, index) => (
            <BrandsItem key={index} {...feature} />
          ))}
        </ul>
        <HowRepairsWorks partnerPage />
      </div>
      <div className={classes.runningLine}>
        <RunningPartnershipsLine />
      </div>
      <div className={`${classes.expertiseSection} container`}>
        <div className={classes.expertiseWrapper}>
          <div className={classes.leftBlock}>
            <img src={expertiseImage} alt="expertise" className={classes.leftPicture} />
          </div>
          <div className={classes.rigthBlock}>
            <Typography component={'h2'} className={classes.expertisTitle}>
              {t('THE REPAIR REBELS EXPERTISE')}
            </Typography>
            <p className={classes.expertiseText}>{t('Expertis text')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandsPartnerships;
