import React from 'react';
import { makeStyles } from '@material-ui/core';
import Marquee from 'react-fast-marquee';
import { brandsTailorPageLogos } from 'CraftsmenSubpage/data/brandsImages';

const useStyles = makeStyles((theme) => {
  return {
    brandsLogosList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      listStyle: 'none',
      padding: '0',
      gap: '40px',
    },
    brandImage: {
      maxWidth: '100%',
    },
    wrapper: {
      paddingTop: '25px',
    },
    listItem: {
      '&:last-child': {
        marginRight: '60px',
      },
    },
  };
});

const BrandsRuningStroke = () => {
  const classes = useStyles();

  return (
    <Marquee className={classes.wrapper}>
      <ul className={classes.brandsLogosList}>
        {brandsTailorPageLogos.map((brand) => (
          <li className={classes.listItem}>
            <img src={brand.img} alt="brand" style={{ width: brand.width, marginRight: brand.marginRight }} className={classes.brandImage} />
          </li>
        ))}
      </ul>
      <ul className={classes.brandsLogosList}>
        {brandsTailorPageLogos.map((brand) => (
          <li className={classes.listItem}>
            <img src={brand.img} alt="brand" style={{ width: brand.width, marginRight: brand.marginRight }} className={classes.brandImage} />
          </li>
        ))}
      </ul>
    </Marquee>
  );
};

export default BrandsRuningStroke;
